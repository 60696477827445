/* eslint-disable */

import React, { useState, useEffect, useRef } from 'react';
import { Container, Typography, FormControl, Select, MenuItem, Button, Grid, Box } from '@material-ui/core';
import DiscountCard from './Card/DiscountsCard';
import { getMedicines, getDiscounts } from '@psp/common';
import useStyles from './styles';
import { useLoading } from '../../contexts/loading.context';
import { PROGRAM_ID } from '../../constants';
import { statesList } from './statesList';

interface RangeDto {
  boxesFrom: number;
  boxesTo: number;
  discountPercentage: number;
}

interface RuleDto {
  ruleName: string;
  ruleId: number;
  productDescription: string;
  ranges: RangeDto[];
}

interface Discount {
  name: string;
  familyDescription: string;
  rules: RuleDto[];
}

interface Medication {
  lineId: number;
  name: string;
}

const DiscountsPage: React.FC = () => {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const { showLoading, hideLoading } = useLoading();
  const [medicines, setMedicines]  = useState<Medication[]>([]);
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [error, setError] = useState<string>('');
  const [selectedMedicineId, setSelectedMedicineId] = useState<number | null>(null);
  const [medicinesName, setMedicinesName] = useState<string>('');
  const [uf, setUf] = useState<string>('');

  useEffect(() => {
    const fetchMedicines = async () => {
      showLoading();
      try {
        const medicinesData = await getMedicines.execute({ programId: PROGRAM_ID });
        if (medicinesData && medicinesData.length > 0) {
          setMedicines(medicinesData.map((medicine: { lineId: string; name: string }) => ({
            lineId: parseInt(medicine.lineId),
            name: medicine.name,
          })));
        } else {
          setMedicines([]);
          setError('Não foram encontrados medicamentos');
        }
      } catch (err) {
        console.log(err);
      } finally {
        hideLoading();
      }
    };

    fetchMedicines();
  }, []);

  const fetchDiscounts = async () => {

    if (!selectedMedicineId || !uf) {
      const message = !selectedMedicineId && !uf 
        ? 'Por favor, selecione um medicamento e um estado para buscar os descontos.'
        : !selectedMedicineId 
          ? 'Por favor, selecione um medicamento para buscar os descontos.'
          : 'Por favor, selecione um estado para buscar os descontos.';
    
      setError(message);
      return;
    }
    setError(''); // Limpa o erro se um medicamento foi selecionado
    showLoading();
    try {
      const discountsData = await getDiscounts.execute({
        programId: 126,
        familyId: selectedMedicineId,
        uf: uf
      });
      const { name, familyDescription, rules } = discountsData;
  
      if (rules && rules.length > 0) {
        setDiscounts(rules.map((rule: RuleDto) => ({
          name,
          familyDescription,
          rules: [{
            ruleName: rule.ruleName,
            ruleId: rule.ruleId,
            productDescription: rule.productDescription,
            ranges: rule.ranges.map((range: RangeDto) => ({
              boxesFrom: range.boxesFrom,
              boxesTo: range.boxesTo,
              discountPercentage: range.discountPercentage,
            })),
          }],
        })));
      } else {
        setDiscounts([]);
        setError('Não foram encontrados descontos para o medicamento.');
      }
    } catch (err) {
      console.log(err);
      setError('Ocorreu um erro ao buscar os descontos.');
    } finally {
      hideLoading();
    }
  };  

  const handleMedicationChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const selectedName = e.target.value as string;
    setMedicinesName(selectedName);
  
    const selectedMedicine = medicines.find(medicine => medicine.name === selectedName);
    if (selectedMedicine) {
      setSelectedMedicineId(selectedMedicine.lineId);
    } else {
      setSelectedMedicineId(null);
    }
  };

  const handleStateChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const selectedName = e.target.value as string;
    setUf(selectedName);
  };

  return (
    <Container className={classes.styledContainer}>
      <Typography variant="h4" gutterBottom className={classes.title}>
        Consulte descontos
      </Typography>
      <Typography variant="body1" gutterBottom className={classes.information}>
        O programa Abraçar a Vida oferece desconto na compra de medicamentos.
      </Typography>
      <hr style={{ margin: '30px 0px', border: '0', borderTop: '3px solid #e0e0e0' }} />
      <Box className={classes.banner}>
        <Typography>
          Verifique os descontos disponíveis para cada medicamento.
        </Typography>
      </Box>
      <Typography className={classes.information}>
        Selecione o medicamento para visualizar os descontos, que podem variar conforme a alíquota de ICMS de cada região.
      </Typography>
      <Box mb={5} mt={2}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={8}>
            <FormControl variant="outlined" fullWidth>
            <Select
              labelId="medication-select-label"
              value={medicinesName}
              onChange={handleMedicationChange}
              displayEmpty
              label="Medicamento"
            >
              <MenuItem value="">
                <em>Selecione um medicamento</em>
              </MenuItem>
              {medicines.map((medicine) => (
                <MenuItem key={medicine.lineId} value={medicine.name}>
                  {medicine.name}
                </MenuItem>
              ))}
            </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
          <FormControl variant="outlined" fullWidth>
            <Select
              labelId="uf-select-label"
              value={uf}
              onChange={handleStateChange}
              displayEmpty
            >
              <MenuItem value="">
                <em>Estado</em>
              </MenuItem>
              {statesList.map((state) => (
                <MenuItem key={state.value} value={state.value}>
                  {state.label}
                </MenuItem>
              ))}
            </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8}>
            {error && (
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.errorText}>
                  {error}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
      <Button variant="contained" color="primary" onClick={fetchDiscounts}>
        Buscar
      </Button>
      <Grid container spacing={2} style={{ marginTop: '16px' }}>
        {discounts.map((discount) => (
          <Grid item xs={12} sm={6} md={4} key={discount.name}>
            <DiscountCard discount={discount} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default DiscountsPage;
