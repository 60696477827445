import React from 'react';
import {
  getExamGroupExams,
  getFollowupExamGroups,
  getInventoryAmount,
  getProgramFollowups,
  getDoctorInventoryAmounts,
  registerProcedure,
  sendVerificationCodeAuthenticated,
  getPatientFields,
  getProcedureFields,
} from '@psp/common';

import ExamRequest, { ExamRequestProps } from '../../components/Exam/Request';

const dispatchers: Pick<
  ExamRequestProps,
  | 'getExamGroupExams'
  | 'getFollowupExamGroups'
  | 'getInventoryAmount'
  | 'getProgramFollowups'
  | 'getDoctorInventoryAmounts'
  | 'registerProcedure'
  | 'sendVerificationCodeAuthenticated'
  | 'getPatientFields'
  | 'getProcedureFields'
> = {
  getExamGroupExams,
  getFollowupExamGroups,
  getInventoryAmount,
  getProgramFollowups,
  getDoctorInventoryAmounts,
  registerProcedure,
  sendVerificationCodeAuthenticated,
  getPatientFields,
  getProcedureFields,
};

export default function ExamRequestComposer(): JSX.Element {
  return <ExamRequest {...dispatchers} />;
}
